import { http } from '@/store/api/http-common.js';

const listNotificationRecipients = async (params) => {
	try {
		const { data } = await http.get('notification-recipients', { params });
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const createNotificationRecipients = async (payload) => {
	try {
		const { data } = await http.post('notification-recipients', payload);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const deleteNotificationRecipients = async (id) => {
	try {
		const { data } = await http.delete(`notification-recipients/${id}`);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

export {
	listNotificationRecipients,
	createNotificationRecipients,
	deleteNotificationRecipients,
};
