import { $EventBus } from '@/main';
import { mapState, mapActions } from 'vuex';
import CDatePicker from '@/components/global/CDatePicker.vue';
import ValidationAlert from '@/components/global/ValidationAlert.vue';
import { createNotificationRecipients } from '@/store/modules/notificationRecipients/service/agreementType.service';

export default {
	name: 'RegisterRecipientDialog',
	props: {
		value: {
			type: Boolean,
			required: false,
		},
	},
	data() {
		return {
			dialog: false,
			valid: true,
			loading: false,
			userForm: {},
			userTable: [],
			users: [],
			requiredRule: [(v) => !!v || 'Campo requerido'],
			numberRules: [
				(v) => !!v || 'Campo requerido',
				(v) => /^\d*$/.test(v) || 'Campo numérico',
				// (v) => v > 0 || 'El valor debe ser mayor que cero',
			],
			headers: [
				{
					text: 'Usuario',
					value: 'user.fullName',
					sortable: false,
					align: 'left',
					class: 'primary--text',
				},
				{
					text: 'Email',
					value: 'user.email',
					sortable: false,
					align: 'left',
					class: 'primary--text',
				},
				{
					text: '',
					value: 'actions',
					sortable: false,
					align: 'left',
					class: 'primary--text',
				},
			],
			loadingUsers: false,
			loadingSave: false,
			loadingSearch: false,
			timeout: null,
		};
	},
	created() {},
	mounted() {},
	computed: {
		...mapState('security', ['usersActive']),
		...mapState('agreement', ['template']),
		myModel: {
			get() {
				return this.value || false;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
	},
	watch: {
		dialog(newVal) {
			if (newVal && this.$refs.userForm) this.$refs.userForm.reset();
		},
	},
	methods: {
		...mapActions('security', ['searchUsers']),

		showAlert(msmError, color = 'error') {
			msmError = Array.isArray(msmError) ? msmError.join('<br> ') : msmError;
			$EventBus.$emit('showSnack', color, msmError);
		},

		// closeDialog() {
		// 	this.$nextTick(() => {
		// 		if (this.$refs?.form) {
		// 			this.$refs.form.resetValidation();
		// 		}
		// 	});
		// },

		async getUsers(params) {
			this.loading = true;
			const { ok, response, error } = await this.searchUsers(params);
			if (ok) {
				this.users = Array.isArray(response) ? response : [];
			} else {
				this.showAlert(error?.data?.message || 'Error');
			}
			this.loading = false;
		},

		async addUser() {
			const isvalidated = this.$refs.userForm.validate();
			if (isvalidated) {
				try {
					this.loadingSave = true;
					await createNotificationRecipients({
						user_id: this.userForm?.user?.id,
					});
					this.showAlert('Información guardada correctamente.', 'success');
					this.$emit('onCreate');
					this.dialog = false;
				} catch (error) {
					this.showAlert(error?.response?.data?.message || error.toString());
				} finally {
					this.loadingSave = false;
				}
			}
		},

		// cleanForm() {
		// 	this.$nextTick(() => {
		// 		this.userForm.user = null;
		// 		if (this.$refs.userForm) this.$refs.userForm.reset();
		// 	});
		// },

		listenSearch(value) {
			clearTimeout(this.timeout);
			if ((value || '').length > 1 || value == '') {
				this.timeout = setTimeout(async () => {
					await this.getUsers({ q: value });
					clearTimeout(this.timeout);
				}, 800);
			}
		},
	},
	components: {
		ValidationAlert,
		CDatePicker,
	},
};
